<template>
  <Layout>
    <PageHeader title="Manufactures" />
    <div class="row mb-2">
      <!-- Search -->
      <div class="col-sm-12 col-md-6">
        <div id="tickets-table_filter" class="dataTables_filter">
          <label class="d-inline-flex align-items-center">
            Search:
            <b-form-input
              v-model="filter"
              type="search"
              placeholder="Search..."
              class="form-control form-control-sm ml-2"
            ></b-form-input>
          </label>
        </div>
      </div>
      <div class="col-6 d-inline-flex justify-content-end">
        <b-button
          class="bseen-btn"
          @click="
            () => {
              this.selectedManufacture = null;
              showActionModal();
            }
          "
          >Add</b-button
        >
      </div>
      <!-- End search -->
    </div>
    <!-- Table -->
    <div class="text-center text-danger my-2" v-if="isBusy">
      <b-spinner class="align-middle"></b-spinner>
    </div>
    <div class="row" v-else>
      <div
        class="col-xl-2 col-md-3 col-sm-4"
        v-for="(data, index) in manufactureData"
        :key="index"
      >
        <div class="card">
          <div class="card-body p-3">
            <div class="d-flex justify-content-between mb-2">
              <div class="d-flex flex-row-reverse">
                <button class="action-btn" @click="deleteUser(data)">
                  <i class="fas fa-trash-alt"></i>
                </button>
                <button class="action-btn" @click="editManufacture(data)">
                  <i class="fas fa-edit"></i>
                </button>
              </div>
              <div
                :style="
                  data.status == 0
                    ? 'color:red;font-weight:900'
                    : 'color:green;font-weight:900'
                "
              >
                <span v-if="data.status == 0">Inactive</span>
                <span v-else>Active</span>
              </div>
            </div>
            <div class="text-center">
              <img :src="data.logo" alt class="avatar-lg mb-2" />
              <div class="media-body">
                <h7 class="text-truncate">
                  <a href="#" class="text-dark font-weight-bold"
                    >{{ data.name }}
                  </a>
                </h7>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <UpdateManufactureModel
      :userRoles="userRoles"
      :selectedManufacture="selectedManufacture"
      v-on:resetModal="selectedManufacture = null"
      v-on:reloadData="getAllManufactures"
    />
  </Layout>
</template>

<script>
// import Layout from "../../layouts/main";
import Layout from "../../../layouts/main";
import appConfig from "@/app.config";
import { vehicle } from "@/config/api/vehicles";
// import UpdateManufactureModel from "@/components/users/actions-modal";
import UpdateManufactureModel from "@/components/vehicle/manufacture/edit-manufacture-vehicle.vue";
import { roles } from "@/config/api/roles";
import moment from "moment";
import PageHeader from "@/components/page-header";

export default {
  page: {
    title: "Advanced Table",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: { Layout, UpdateManufactureModel, PageHeader },
  data() {
    return {
      manufactureData: [],
      selectedManufacture: null,
      userRoles: [],
      isBusy: false,
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      sortBy: "age",
      sortDesc: false,
      fields: [
        { key: "name", sortable: false },
        { key: "statusText", label: "Status", sortable: false },
        { key: "logo", sortable: false },
        { key: "actions", sortable: false },
      ],
    };
  },
  computed: {
    rows() {
      return this.manufactureData.length;
    },
  },
  mounted() {},
  created() {
    this.getAllManufactures();
    // this.getAllRoles();
  },
  methods: {
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    getAllManufactures() {
      this.isBusy = true;
      const api = vehicle.manufacture.get;
      console.log(api);
      this.generateAPI(api)
        .then((res) => {
          for (var manufacture in res.data.manufactures) {
            res.data.manufactures[manufacture].statusText =
              res.data.manufactures[manufacture].status == 0
                ? "Pending"
                : "Confirmed";
            if (res.data.manufactures[manufacture].deletedBy != null) {
              res.data.manufactures[manufacture].deletedByUser =
                res.data.manufactures[manufacture].deletedBy.name;
              res.data.manufactures[manufacture].deletedDate = moment(
                res.data.manufactures[manufacture].deletedAt
              ).format("dddd, MMMM Do YYYY, h:mm:ss a");
            }
          }

          console.log("=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-");
          console.log("Generate API");
          console.log(res.data.manufactures);
          console.log("=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-");
          this.manufactureData = res.data.manufactures;
          this.totalRows = this.manufactureData.length;
        })
        .catch((err) => {
          console.log("=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-");
          console.log(err);
          console.log("=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-");
          //   console.error(err.response.data);
        })
        .finally(() => {
          this.isBusy = false;
        });
    },
    showActionModal() {
      this.$bvModal.show("add-edit-manufacture");
    },
    getAllRoles() {
      const data = roles.get;
      this.generateAPI(data)
        .then((res) => {
          this.userRoles = res.data.roles;
        })
        .catch((err) => {
          console.error(err.response.data);
        });
    },
    editManufacture(data) {
      console.log(data);
      this.selectedManufacture = data;
      this.showActionModal();
    },
    deleteUser(data) {
      this.$bvModal
        .msgBoxConfirm(`Please confirm that you want to delete ${data.name}.`, {
          title: "Please Confirm",
          size: "sm",
          buttonSize: "sm",
          okVariant: "danger",
          okTitle: "YES",
          cancelTitle: "NO",
          footerClass: "p-2",
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value) {
            const api = vehicle.manufacture.delete;
            api.id = data._id;
            this.generateAPI(api)
              .then((res) => {
                console.log(res.data);
                this.$bvToast.toast("Manufacture deleted successfully!", {
                  title: "Delete",
                  variant: "success",
                  solid: true,
                });
                this.getAllManufactures();
              })
              .catch((err) => {
                this.$bvToast.toast("Manufacture is not deleted!", {
                  title: "Delete",
                  variant: "danger",
                  solid: true,
                });
                console.error(err.response.data);
              });
          }
        })
        .catch((err) => {
          console.error(err);
          // An error occurred
        });
    },
  },
};
</script>
